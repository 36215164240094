.top-benefits {
  padding: 48px 0;
  overflow: hidden;

  &-header {
    margin: 0 0 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }

  &-header-title {
    margin: 0;
  }

  &-row {
    display: flex;
    flex-direction: column;
    gap: 24px 50px;

    &:first-child {
      margin: 0 0 35px;
    }

    @media (min-width: 768px) {
      &:first-child {
        margin: 0 0 80px;
      }
    }

    @media (min-width: 992px) {
      align-items: center;
      flex-direction: row;
    }
  }

  &-content-wrap {
    flex: 1;
  }

  &-content {
    display: block;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &-item {
    position: relative;
    padding: 0 0 0 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #111;

    &:before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      content: '';
      background: #823cdc;
    }

    @media (min-width: 768px) {
      font-size: 16px;

      &:before {
        top: 10px;
      }
    }
  }

  &-img-wrap {
    order: 1;

    @media (min-width: 992px) {
      width: 635px;
      order: initial;
    }
  }

  &-img {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 16px;
    display: block;

    &.is-has-shadow {
      box-shadow: 0 7px 24px rgba(0, 0, 0, .2);
    }
  }

  &-button-wrap {
    margin: 12px 0 0;
    display: flex;

    @media (min-width: 768px) {
      margin: 24px 0 0;
      display: block;
    }

    @media (min-width: 992px) {
      margin: 32px 0 0;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
