.our-clients {
  position: relative;
  padding: 48px 0 0;
  overflow: hidden;

  &-circle-wrap {
    position: sticky;
    top: 120px;
    height: calc(100vh - 100px);
    display: none;
    overflow: hidden;

    @media (min-width: 1200px) {
      display: block;
    }
  }

  &-circle {
    top: calc(100vh - 260px);
  }

  &-container {
    @media (min-width: 1200px) {
      margin: calc(-100vh + 100px) 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &-title-container {
    @media (min-width: 1200px) {
      position: sticky;
      top: 120px;
      width: 290px;
      height: calc(100vh - 120px);
    }
  }

  &-list {
    position: relative;
    z-index: 2;
    padding: 24px 16px 48px; // for box-shadow (don't cut shadow)
    margin: -24px -16px 0; // for box-shadow (don't cut shadow)
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 28px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 768px) {
      padding: 32px 32px 120px; // for box-shadow (don't cut shadow)
      margin: -32px -32px 0; // for box-shadow (don't cut shadow)
    }

    @media (min-width: 1200px) {
      width: 720px;
      padding: 120px 60px;
      margin: -120px -45px 0;
      gap: 40px;
      flex-flow: row wrap;
    }
  }

  &-item {
    display: flex;
    gap: 28px;

    @media (min-width: 1200px) {
      margin: 0;
      flex-direction: column;
      gap: 40px;

      &:nth-of-type(2n) {
        margin: 64px 0 0;
      }
    }
  }

  &-img {
    width: 260px;
    height: 108px;
    border-radius: 16px;
    display: block;
    flex-shrink: 0;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      width: 280px;
      height: 128px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0 0;
  }

  @media (min-width: 1200px) {
    overflow: visible;
  }
}
