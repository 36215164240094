.interactive-platform {
  position: relative;
  color: #111;
  background: #fff;

  .container-interactive-platform {
    padding: 48px 16px;

    @media (min-width: 768px) {
      padding: 120px 32px;
    }
  }

  .text-container {
    max-width: 920px;
    margin: auto;
  }

  .title {
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 160%;

    @media (min-width: 768px) {
      margin: 0 0 32px;
      font-size: 32px;
    }
  }

  .highlight {
    position: relative;
    margin: 0 5px;
    display: inline-block;
    vertical-align: baseline;

    &:after {
      position: absolute;
      top: 55%;
      left: 0;
      z-index: -1;
      width: 106%;
      height: 42%;
      margin: 0 0 0 -3%;
      border-radius: 4px;
      content: '';
      background: #fdb548;

      @media (min-width: 768px) {
        width: 102%;
        height: 37%;
        margin: 0 0 0 -1%;
      }
    }

    &:hover {
      .hint {
        visibility: visible;
        opacity: 1;
        transform: scale(1, 1) translate(-50%, 0);

        @media (min-width: 1200px) {
          transform: scale(1, 1) translate(0, 0);
        }
      }
    }
  }

  .is-blue {
    &:after {
      background: #3993fd;
    }

    @media (min-width: 1200px) {
      &:hover {
        .hint {
          transform: scale(1, 1) translate(-50%, 0);
        }
      }

      .hint {
        left: 5%;
        transform: scale(1, 1) translate(-50%, 10px);

        &:before {
          left: 90%;
        }
      }
    }
  }

  .hint {
    width: auto;
    padding: 16px 18px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #fff;
    display: block;
    background: #111;
    transform: scale(1, 1) translate(-50%, 10px);
    box-shadow: 0 28px 68px rgba(55, 87, 67, .5);

    &:before {
      border-color: #111 transparent;
    }

    @media (min-width: 768px) {
      padding: 15px;
      font-size: 16px;
    }

    @media (min-width: 1200px) {
      padding: 24px;
      font-size: 18px;
    }

    @media (min-width: 1200px) {
      left: 0;
      transform: scale(1, 1) translate(0, 10px);

      &:before {
        top: -8px;
        left: 8%;
        border-width: 0 8px 8px;
      }
    }
  }

  .text {
    max-width: 730px;
    font-size: 16px;
    line-height: 160%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .list {
    padding: 0 0 0 24px;
    list-style: initial;
  }

  .list-item {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.6;
    color: #111;
  }

  .circle-wrapper {
    position: relative;
  }

  .circle-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    margin: 10px 0 0;
    overflow: hidden;
  }

  .button-wrapper {
    margin: 28px 0 0;

    @media (min-width: 768px) {
      margin: 32px 0 0;
    }
  }

  .slider {
    padding: 90px 0 8px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (min-width: 768px) {
      padding: 160px 0 28px;
      gap: 80px;
    }

    @media (min-width: 1200px) {
      padding: 80px 0 90px;
      align-items: center;
      flex-direction: row;
      gap: 30px;
    }
  }

  .slide-wrapper {
    &:first-child .slider-title {
      border-top: 0;
    }
  }

  .is-active {
    .slider-title {
      color: #111;
    }

    .slider-item-content-wrap {
      grid-template-rows: 1fr;
    }
  }

  .slider-container-info {
    position: relative;
    flex: 1;
    overflow: hidden;
    transition: height .6s;
    will-change: height;

    @media (min-width: 1200px) {
      padding: 140px 0 0;
    }
  }

  .slider-title {
    width: 100%;
    padding: 16px 0;
    border: 0;
    border-top: 2px solid #e7e7e7;
    font-size: 18px;
    font-family: inherit;
    font-weight: 700;
    line-height: 130%;
    color: #8c4af5;
    text-align: left;
    display: block;
    background: none;
    transition: color 0.2s ease;

    @media (min-width: 768px) {
      padding: 24px 0;
      font-size: 24px;
    }
  }

  .slider-item-content-wrap {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.4s ease;
  }

  .slider-item-content {
    overflow: hidden;
  }

  .slider-text {
    font-size: 16px;
    line-height: 160%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .slider-list {
    padding: 0 0 16px 27px;
    margin: 0;
    list-style: unset;

    @media (min-width: 768px) {
      padding: 0 0 24px 27px;
    }
  }

  .slider-list-item {
    font-size: 16px;
    line-height: 160%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .slider-media {
    position: relative;
    width: 242px;
    height: 297px;
    margin: 0 0 0 calc(50% - 163px); // 327/2 - full width block / 2

    @media (min-width: 768px) {
      width: 445px;
      height: 520px;
      margin: 0 0 0 calc(50% - 260px); // 520/2 - full width block / 2
    }

    @media (min-width: 1200px) {
      width: 380px;
      height: 387px;
      margin: 115px 45px 0 0;
    }

    @media (min-width: 1200px) and (max-height: 860px) {
      width: 380px;
      height: 387px;
    }

    @media (min-width: 1200px) {
      width: 510px;
      height: 520px;
    }

    @media (min-width: 1200px) and (max-height: 860px) {
      width: 444px;
      height: 452px;
    }
  }

  .slider-picture-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .slider-picture {
    position: absolute;
    width: 100%;
    height: auto;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s linear, visibility .4s linear;

    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  .picture-img {
    width: 100%;
    height: auto;
    border-radius: 16px;
    display: block;
  }

  .call-and-platform,
  .container-speaking-club-and-apps {
    z-index: 2;
  }

  .call-and-platform {
    position: absolute;
    top: -30px;
    right: -90px;

    @media (min-width: 768px) {
      top: -48px;
    }
  }

  .mobile-apps,
  .speaking-club,
  .online-platform,
  .teacher-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: transform .1s linear;
    will-change: transform;
  }

  .teacher-container {
    width: 178px;
    padding: 15px 15px 22px;
    margin: 0 0 8px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 15.0909px 50.303px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      width: 186px;
      padding: 24px;
      margin: 0 0 12px 26px;
    }
  }

  .teacher-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .teacher-img {
    width: 62px;
    height: 62px;
    margin: 10px auto 5px;

    @media (min-width: 768px) {
      width: 99px;
      height: 99px;
      margin: 20px auto 8px;
    }
  }

  .teacher-controls {
    display: flex;
    gap: 4px;
  }

  .teacher-control-item {
    width: 20px;
    height: 20px;

    @media (min-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }

  .online-platform,
  .mobile-apps-text,
  .speaking-club-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .online-platform {
    padding: 21px 12px;
    border-radius: 12px;
    background: #823cdc;
    box-shadow: 0 15.0909px 50.303px rgba(55, 87, 67, .1);
  }

  .container-speaking-club-and-apps {
    position: absolute;
    right: -48px;
    bottom: -7px;
    display: flex;
    gap: 0 7px;

    @media (min-width: 768px) {
      bottom: -28px;
    }

    @media (min-width: 1200px) {
      bottom: -92px;
    }
  }

  .speaking-club {
    min-width: 150px;
    padding: 27px 12px 23px;
    border-radius: 12px;
    background: #823cdc;
    box-shadow: 0 15.0909px 50.303px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      min-width: 240px;
      padding: 44px 22px 38px;
    }
  }

  .speaking-club-img {
    width: 122px;
    height: 20px;
    margin: 4px auto 0;
    display: block;

    @media (min-width: 768px) {
      width: 194px;
      height: 32px;
      margin: 13px auto 0;
    }
  }

  .mobile-apps {
    width: 95px;
    height: 95px;
    padding: 25px 10px 0;
    border-radius: 50%;
    text-align: center;
    background: #823cdc;

    @media (min-width: 768px) {
      width: 150px;
      height: 150px;
      padding: 45px 15px 0;
    }
  }
}
