.header-informer {
  position: fixed;
  width: 100%;
  padding: 9px 10px 15px;
  opacity: 0;
  background: #005bbb;
  transition: opacity .4s;

  &.is-error,
  &.is-visible,
  &.is-always-visible {
    z-index: 1031;
  }

  &.is-error {
    z-index: 1032;
    background: #ee6f69;
  }

  &.is-visible {
    animation: showHide 3s ease;
  }

  &.is-promo {
    padding: 19px 10px;
  }

  &.is-always-visible {
    opacity: 1;
  }

  .close {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 12px;
    height: 12px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transform: translateY(-50%);
    background: url(./assets/close.svg) no-repeat 0/contain;
  }

  .text {
    max-width: 765px;
    padding: 0 30px 0 0;
    margin: 0 auto;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  /*
  * Colors
  */

  &.blue {
    background: #005bbb;

    .text {
      color: #fff;
    }
  }

  &.purple {
    background: #823CDC;

    .text {
      color: #fff;
    }
  }
}

@keyframes showHide {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}
