.application-popup {
  .success {
    max-width: 500px;
    padding: 24px 0 0;
    margin: auto;
    color: #111;
    text-align: center;

    @media (min-width: 768px) and (min-height: 900px) {
      max-width: 704px;
      padding: 40px 0 0;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      max-width: 730px;
      padding: 64px 0 0;
    }
  }

  .success-icon {
    width: 88px;
    height: 88px;
    margin: 0 auto;
    display: block;
  }

  .success-title {
    margin: 16px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;

    @media (min-width: 768px) {
      margin: 24px 0;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      margin: 32px 0;
      font-size: 24px;
    }
  }

  .success-text {
    max-width: 400px;
    margin: 0 auto 16px;
    font-size: 16px;
    line-height: 160%;

    @media (min-width: 768px) {
      margin: 0 auto 24px;
      font-size: 18px;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      max-width: inherit;
      margin: 0 auto 32px;
    }
  }

  .success-additional-info {
    padding: 24px 12px;
    border-radius: 16px;
    background: #f5f7fa;

    @media (min-width: 768px) {
      padding: 30px 24px;
      display: flex;
      align-items: center;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      padding: 30px;
    }
  }

  .success-additional-info-text,
  .success-additional-info-link {
    font-size: 18px;
    font-weight: 700;
    line-height: 160%;
    color: #111;
    text-align: left;
  }

  .success-additional-info-text:first-of-type {
    margin: 0 0 16px;

    @media (min-width: 768px) {
      margin: 0 24px 0 0;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      margin: 0 32px 0 0;
    }
  }

  .success-additional-info-link {
    text-decoration: none;
  }
}
