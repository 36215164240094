.individual-offer {
  padding: 48px 0 0;
  background: #f5F7fa;

  &-form {
    padding: 48px 16px;
    border-radius: 16px;
    text-align: center;
    background-image: url(./assets/individual-offer-bg--mobile.webp);
    background-size: cover;
    background-position: 50% 50%;

    @media (min-width: 768px) {
      padding: 80px 30px;
      background-image: url(./assets/individual-offer-bg--tablet.webp);
    }

    @media (min-width: 1200px) {
      padding: 80px 80px 70px 100px;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      background-image: url(./assets/individual-offer-bg.webp);
    }
  }

  &-info {
    width: 250px;
    margin: 0 auto 24px;

    @media (min-width: 768px) {
      width: auto;
      margin: 0 0 40px;
    }

    @media (min-width: 1200px) {
      width: 500px;
      margin: 0;
    }
  }

  &-title {
    margin: 0 0 16px;
  }

  &-text {
    margin: 0 0 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    text-wrap: balance;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &-picture-container {
    padding: 23px 0 24px;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      padding: 40px 20px 25px 77px;
    }

    @media (min-width: 1200px) {
      padding: 0;
    }
  }

  &-picture {
    position: relative;
    width: 296px;
    height: 297px;

    @media (min-width: 768px) {
      width: 364px;
      height: 325px;
    }
  }

  &-picture-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    color: #fff;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  &-picture-icon {
    font-size: 20px;
    line-height: 1;
  }

  &-picture-define-goals-wrap {
    position: absolute;
    top: -23px;
    left: 9px;
    width: fit-content;
    height: fit-content;

    @media (min-width: 768px) {
      top: -40px;
      left: -40px;
    }
  }

  &-picture-show-platform-wrap {
    position: absolute;
    left: -8px;
    bottom: -16px;
    width: fit-content;
    height: fit-content;

    @media (min-width: 768px) {
      left: -77px;
      bottom: 20px;
    }
  }

  &-picture-offer-solution-wrap {
    position: absolute;
    right: 13px;
    bottom: -16px;
    width: fit-content;
    height: fit-content;

    @media (min-width: 768px) {
      right: -20px;
      bottom: -25px;
    }
  }

  &-picture-define-goals {
    width: 120px;
    height: 120px;
    padding: 0 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
    background: #823cdc;

    @media (min-width: 768px) {
      width: 140px;
      height: 140px;
    }
  }

  &-picture-show-platform {
    width: 114px;
    height: 114px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    background: #823cdc;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      width: 140px;
      height: 140px;
    }
  }

  &-picture-offer-solution {
    width: 160px;
    height: 48px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: #823cdc;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      width: 190px;
      height: 64px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
