.application-form-with-labels {
  text-align: left;

  .button-wrapper {
    margin: 24px auto 0;
    text-align: center;
  }

  .button-container {
    min-width: 210px;
    display: inline-block;
    vertical-align: middle;
  }

  .agreement,
  .agreement-link {
    margin: 24px 0 0;
    font-size: 16px;
    line-height: 160%;
    color: rgba(#111, .5);
    text-align: center;
  }

  .agreement-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
