/* stylelint-disable max-nesting-depth */

.input-vintage {
  position: relative;
  color: #111;
  text-align: left;

  /**
   * Status styles
   */

  &:hover {
    .hint {
      visibility: visible;
      opacity: 1;
      transform: scale(1, 1) translate(-50%, 0);
    }
  }

  &:not(:last-of-type) {
    margin: 0 0 16px;
  }

  &.is-error {
    .input {
      border: 1px solid #fe3030;
    }

    &.size-m,
    &.size-s,
    &.size-xs {
      .error {
        margin: 8px 0 0;
      }
    }
  }

  &.is-disabled {
    .input {
      border: 1px solid transparent;
      background: rgba(#111, .05);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  /**
   * Hint styles
   */

  .hint {
    font-family: 'Open Sans', sans-serif;
  }

  .input-container {
    position: relative;
  }

  /**
   * Input styles
   */

  .input {
    width: 100%;
    outline: 0;
    border-radius: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    line-height: 1.6;
    color: #111;
    display: block;
    background: #fff;
    transition: border .2s;
    box-sizing: border-box;

    &::placeholder {
      font-weight: 600;
      color: #111;
    }

    @media (min-width: 768px) and (min-height: 900px) {
      height: 66px;
    }
  }

  .label {
    display: block;

    &:after {
      position: absolute;
      top: 0;
      left: 30px;
      font-size: 18px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      color: #111;
      opacity: 1;
      transition: opacity .2s, line-height .2s, font-size .2s;
      content: attr(data-placeholder);
      cursor: text;

      @media (min-width: 768px) and (min-height: 900px) {
        line-height: 66px;
      }
    }
  }

  .error {
    margin: 0;
    font-size: 0;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: #fe3030;
    visibility: hidden;
    opacity: 0;
    transition: font-size .2s, margin .2s;

    &:not(:empty) {
      margin: 16px 0 0;
      font-size: 12px;
      visibility: visible;
      opacity: 1;
    }
  }

  /**
   * Variant styles
   */

  &.variant-border {
    .input {
      border: 1px solid #111;
    }

    &.is-error {
      .input {
        border: 1px solid #fe3030;
      }
    }
  }

  /**
   * Type styles (for phone)
   */

  &.type-phone {
    &.size-l {
      .input {
        padding: 18.5px 24px 18.5px 60px;
      }

      :global {
        .js-phone-icon {
          left: 30px !important; /* stylelint-disable-line */
          max-width: 20px !important; /* stylelint-disable-line */
        }
      }

      @media screen and (max-width: 767px) {
        .input {
          padding: 15px 24px 15px 54px;
        }

        :global {
          .js-phone-icon {
            left: 24px !important; /* stylelint-disable-line */
            max-width: 20px !important; /* stylelint-disable-line */
          }
        }
      }
    }

    &.size-m {
      .input {
        padding: 15px 24px 15px 54px;
      }

      :global {
        .js-phone-icon {
          left: 24px !important; /* stylelint-disable-line */
          max-width: 20px !important; /* stylelint-disable-line */
        }
      }
    }

    &.size-s {
      .input {
        padding: 11px 24px 11px 40px;
      }

      :global {
        .js-phone-icon {
          left: 16px !important; /* stylelint-disable-line */
          max-width: 16px !important; /* stylelint-disable-line */
        }
      }
    }
  }

  /**
   * Size styles
   */

  &.size-l,
  &.size-m {
    &.is-focused {
      .label:after {
        font-size: 12px;
        line-height: 30px;
        opacity: .6;
      }
    }
  }

  &.size-s,
  &.size-xs {
    .input:not(:placeholder-shown) ~ .label:after {
      opacity: 0;
    }
  }

  &.size-l {
    .label:after {
      left: 30px;
      font-size: 18px;
      line-height: 66px;
    }

    .input {
      height: 66px;
      padding: 23px 30px 10px;
      font-size: 18px;
    }

    @media screen and (max-width: 767px) {
      .label:after {
        left: 24px;
        font-size: 16px;
        line-height: 56px;
      }

      .input {
        height: 56px;
        padding: 25px 24px 10px;
        font-size: 16px;
      }
    }
  }

  &.size-m {
    .label:after {
      left: 24px;
      font-size: 16px;
      line-height: 56px;
    }

    .input {
      height: 56px;
      padding: 25px 24px 10px;
      font-size: 16px;
    }
  }

  &.size-s {
    .label:after {
      left: 16px;
      font-size: 16px;
      line-height: 48px;
    }

    &.is-focused {
      .label:after {
        opacity: .6;
      }
    }

    .input {
      height: 48px;
      padding: 11px 16px;
      border-radius: 12px;
      font-size: 16px;
    }
  }

  &.size-xs {
    .label:after {
      left: 16px;
      font-size: 12px;
      line-height: 34px;
    }

    &.is-focused {
      .label:after {
        opacity: .6;
      }
    }

    .input {
      height: 34px;
      padding: 16px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  /**
   * Clean type
   */

  &.variant-clean,
  &.variant-clean .input,
  &.variant-clean .input-container {
    width: 100%;
    height: 100%;
    min-height: inherit;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-align: inherit;
    resize: none;
    background: inherit;

    &::placeholder {
      font-size: inherit;
      font-weight: inherit;
      color: rgba(#111, .5);
    }

    &.is-disabled {
      .input {
        border: 1px solid transparent;
        color: #111;
        background: rgba(#111, .05);

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
/* stylelint-enable max-nesting-depth */
