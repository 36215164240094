.application-popup-wrapper {
  padding: 30px 0 0;

  .popup-content {
    max-width: 500px;
    padding: 1px 16px 32px;
    margin: auto;
    border-radius: 16px;
    background: #fff;

    @media (min-width: 768px) {
      width: 520px;
      max-width: inherit;
      padding: 1px 40px 40px;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      width: 730px;
      padding: 1px 95px 64px;
    }
  }

  .label-bonus {
    padding: 16px;
    margin: -30px auto 0;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    color: #fff;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: #823cdc;
    box-shadow: 0 28px 68px rgba(#823cdc, .5);

    @media (min-width: 768px) and (min-height: 900px) {
      font-size: 18px;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      padding: 16px 24px;
    }
  }

  .title {
    max-width: 410px;
    margin: 32px auto 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    color: #111;
    text-align: center;

    @media (min-width: 768px) and (min-height: 900px) {
      margin: 40px auto 32px;
      font-size: 20px;
    }

    @media (min-width: 1200px) and (min-height: 900px) {
      margin: 64px auto;
      font-size: 24px;
    }
  }
}
